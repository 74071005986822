@font-face {
    font-family: 'nordique_probold';
    src: url('../fonts/leksen_design_-_nordique_pro_bold.woff2') format('woff2'),
         url('../fonts/leksen_design_-_nordique_pro_bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300;
  line-height: 1.2;
  font-style: normal;
  font-size: inherit;
  font-stretch: normal;
  font-weight: bold;
  font-family: 'nordique_probold';
  letter-spacing: normal;
  margin: 0 0 0.25em 0;
}

h5 {
  font-weight: 400;
}
h6 {
  font-weight: 600;
}

p {
  font-size: 1.5em;
  margin: 1em 0;
  font-weight: 300;
  line-height: 1.45;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
}

a {
  text-decoration: none;
}
